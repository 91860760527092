import React from 'react'
import { css } from 'linaria'
import { StaticImage } from "gatsby-plugin-image";

const picArea = css`
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left:0;
    top: 0;
    
    transform: skewY(2deg);
    z-index: -1;
    width:100%;
    height:100%;
    background:#eee;
    border-top-right-radius: 2000px 400px;
    border-bottom-left-radius: 2000px 300px;
  }
`
const flexImgs = css`
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width:1000px) {
    flex-direction: column-reverse;
  }
`
const _img10 = css`
  width: clamp(50%, (1000px - 100vw)*1000, 100%);
  position: relative;
  min-height: 500px;
`
const _oneImg01 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 45%;
  top: 2%;
  left: 5%;
  transform: rotate(-5deg);
  z-index: 1;
  animation: anime-_oneImg01 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg01 {
    0% {
      top: 2%;
      left: 5%;
      transform: rotate(-5deg);
      border: solid 1px #435847;
      z-index: 1;
    }
    10% {
      top: 13%;
      left: 55%;
      transform: rotate(0);
      border: solid 1px #75394D;
      z-index: 2;
    }
    20% {
      top: 6%;
      left: 25%;
      transform: rotate(3deg);
      border: solid 1px #398793;
      z-index: 3;
    }
    30% {
      top: 25%;
      left: 15%;
      transform: rotate(7deg);
      border: solid 1px #F4CA66;
      z-index: 4;
    }
    40% {
      top: 20%;
      left: 25%;
      transform: rotate(2deg);
      border: solid 1px #9EB394;
      z-index: 5;
    }
    50% {
      top: 2%;
      left: 15%;
      transform: rotate(-6deg);
      border: solid 1px #ECC2AA;
      z-index: 6;
    }
    60% {
      top: 5%;
      left: 35%;
      transform: rotate(-2deg);
      border: solid 1px #35447B;
      z-index: 7;
      filter: invert(0);
    }
    70% {
      top: 7%;
      left: 5%;
      transform: rotate(3deg);
      border: solid 1px #BA2A2A;
      z-index: 8;
      filter: invert(100%);
    }
    80% {
      top: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #350023;
      z-index: 9;
      filter: invert(0);
    }
    90% {
      top: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #A7B4AA;
      z-index: 10;
    }
    100% {
      top: 2%;
      left: 5%;
      transform: rotate(-5deg);
      border: solid 1px #435847;
      z-index: 1;
    }
  }
`

const _oneImg02 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 35%;
  top: 2%;
  right: 5%;
  transform: rotate(5deg);
  z-index: 2;
  animation: anime-_oneImg02 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg02 {
    0% {
      top: 2%;
      right: 5%;
      transform: rotate(5deg);
      border: solid 1px #435847;
      z-index: 2;
    }
    10% {
      top: 2%;
      right: 15%;
      transform: rotate(3deg);
      border: solid 1px #75394D;
      z-index: 3;
    }
    20% {
      top: 10%;
      right: 15%;
      transform: rotate(-1deg);
      border: solid 1px #398793;
      z-index: 4;
    }
    30% {
      top: 5%;
      right: 25%;
      transform: rotate(2deg);
      border: solid 1px #F4CA66;
      z-index: 5;
    }
    40% {
      top: 2%;
      right: 15%;
      transform: rotate(0);
      border: solid 1px #9EB394;
      z-index: 6;
    }
    50% {
      top: 5%;
      right: 25%;
      transform: rotate(-7deg);
      border: solid 1px #ECC2AA;
      z-index: 7;
      filter: invert(0);
    }
    60% {
      top: 10%;
      right: 5%;
      transform: rotate(-3deg);
      border: solid 1px #35447B;
      z-index: 8;
      filter: invert(100%);
    }
    70% {
      top: 30%;
      right: 25%;
      transform: rotate(3deg);
      border: solid 1px #BA2A2A;
      z-index: 9;
      filter: invert(0);
    }
    80% {
      top: 30%;
      right: 35%;
      transform: rotate(0);
      border: solid 1px #350023;
      z-index: 10;
    }
    90% {
      top: 20%;
      right: 15%;
      transform: rotate(0);
      border: solid 1px #A7B4AA;
      z-index: 1;
    }
    100% {
      top: 2%;
      right: 5%;
      transform: rotate(5deg);
      border: solid 1px #435847;
      z-index: 2;
    }
  }
`

const _oneImg03 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 45%;
  bottom: 2%;
  right: 5%;
  transform: rotate(3deg);
  z-index: 3;
  animation: anime-_oneImg03 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg03 {
    0% {
      bottom: 2%;
      right: 5%;
      transform: rotate(3deg);
      border: solid 1px #435847;
      z-index: 3;
    }
    10% {
      bottom: 12%;
      right: 15%;
      transform: rotate(-3deg);
      border: solid 1px #75394D;
      z-index: 4;
    }
    20% {
      bottom: 4%;
      right: 25%;
      transform: rotate(2deg);
      border: solid 1px #398793;
      z-index: 5;
    }
    30% {
      bottom: 5%;
      right: 5%;
      transform: rotate(-2deg);
      border: solid 1px #F4CA66;
      z-index: 6;
    }
    40% {
      bottom: 20%;
      right: 15%;
      transform: rotate(1deg);
      border: solid 1px #9EB394;
      z-index: 7;
      filter: invert(0);
    }
    50% {
      bottom: 5%;
      right: 5%;
      transform: rotate(-6deg);
      border: solid 1px #ECC2AA;
      z-index: 8;
      filter: invert(100%);
    }
    60% {
      bottom: 8%;
      right: 5%;
      transform: rotate(0);
      border: solid 1px #35447B;
      z-index: 9;
      filter: invert(0);
    }
    70% {
      bottom: 30%;
      right: 35%;
      transform: rotate(0);
      border: solid 1px #BA2A2A;
      z-index: 10;
    }
    80% {
      bottom: 30%;
      right: 35%;
      transform: rotate(-3deg);
      border: solid 1px #350023;
      z-index: 1;
    }
    90% {
      bottom: 20%;
      right: 15%;
      transform: rotate(-5deg);
      border: solid 1px #A7B4AA;
      z-index: 2;
    }
    100% {
      bottom: 2%;
      right: 5%;
      transform: rotate(3deg);
      border: solid 1px #435847;
      z-index: 3;
    }
  }
`

const _oneImg04 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 35%;
  bottom: 2%;
  left: 5%;
  transform: rotate(2deg);
  z-index: 4;
  animation: anime-_oneImg04 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg04 {
    0% {
      bottom: 2%;
      left: 5%;
      transform: rotate(2deg);
      border: solid 1px #435847;
      z-index: 4;
    }
    10% {
      bottom: 5%;
      left: 25%;
      transform: rotate(3deg);
      border: solid 1px #75394D;
      z-index: 5;
    }
    20% {
      bottom: 40%;
      left: 15%;
      transform: rotate(2deg);
      border: solid 1px #398793;
      z-index: 6;
    }
    30% {
      bottom: 25%;
      left: 5%;
      transform: rotate(-2deg);
      border: solid 1px #F4CA66;
      z-index: 7;
      filter: invert(0);
    }
    40% {
      bottom: 20%;
      left: 15%;
      transform: rotate(6deg);
      border: solid 1px #9EB394;
      z-index: 8;
      filter: invert(100%);
    }
    50% {
      bottom: 15%;
      left: 5%;
      transform: rotate(-3deg);
      border: solid 1px #ECC2AA;
      z-index: 9;
      filter: invert(0);
    }
    60% {
      bottom: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #35447B;
      z-index: 10;
    }
    70% {
      bottom: 8%;
      left: 15%;
      transform: rotate(-3deg);
      border: solid 1px #BA2A2A;
      z-index: 1;
    }
    80% {
      bottom: 30%;
      left: 35%;
      transform: rotate(-7deg);
      border: solid 1px #350023;
      z-index: 2;
    }
    90% {
      bottom: 10%;
      left: 15%;
      transform: rotate(-2deg);
      border: solid 1px #A7B4AA;
      z-index: 3;
    }
    100% {
      bottom: 2%;
      left: 5%;
      transform: rotate(2deg);
      border: solid 1px #435847;
      z-index: 4;
    }
  }
`

const _oneImg05 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 45%;
  top: 20%;
  left: 25%;
  transform: rotate(2deg);
  z-index: 5;
  animation: anime-_oneImg05 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg05 {
    0% {
      top: 20%;
      left: 25%;
      transform: rotate(2deg);
      border: solid 1px #435847;
      z-index: 5;
    }
    10% {
      top: 15%;
      left: 15%;
      transform: rotate(7deg);
      border: solid 1px #75394D;
      z-index: 6;
    }
    20% {
      top: 40%;
      left: 17%;
      transform: rotate(3deg);
      border: solid 1px #398793;
      z-index: 7;
      filter: invert(0);
    }
    30% {
      top: 25%;
      left: 15%;
      transform: rotate(-2deg);
      border: solid 1px #F4CA66;
      z-index: 8;
      filter: invert(100%);
    }
    40% {
      top: 20%;
      left: 20%;
      transform: rotate(2deg);
      border: solid 1px #9EB394;
      z-index: 9;
      filter: invert(0);
    }
    50% {
      top: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #ECC2AA;
      z-index: 10;
    }
    60% {
      top: 2%;
      left: 10%;
      transform: rotate(3deg);
      border: solid 1px #35447B;
      z-index: 1;
    }
    70% {
      top: 20%;
      left: 2%;
      transform: rotate(-5deg);
      border: solid 1px #BA2A2A;
      z-index: 2;
    }
    80% {
      top: 10%;
      left: 15%;
      transform: rotate(-3deg);
      border: solid 1px #350023;
      z-index: 3;
    }
    90% {
      top: 20%;
      left: 20%;
      transform: rotate(0);
      border: solid 1px #A7B4AA;
      z-index: 4;
    }
    100% {
      top: 20%;
      left: 25%;
      transform: rotate(2deg);
      border: solid 1px #435847;
      z-index: 5;
    }
  }
`

const _oneImg06 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 45%;
  top: 2%;
  right: 25%;
  transform: rotate(6deg);
  z-index: 6;
  animation: anime-_oneImg06 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg06 {
    0% {
      top: 2%;
      right: 25%;
      transform: rotate(6deg);
      border: solid 1px #435847;
      z-index: 6;
    }
    10% {
      top: 15%;
      right: 15%;
      transform: rotate(-3deg);
      border: solid 1px #75394D;
      z-index: 7;
      filter: invert(0);
    }
    20% {
      top: 30%;
      right: 7%;
      transform: rotate(-7deg);
      border: solid 1px #398793;
      z-index: 8;
      filter: invert(100%);
    }
    30% {
      top: 3%;
      right: 15%;
      transform: rotate(6deg);
      border: solid 1px #F4CA66;
      z-index: 9;
      filter: invert(0);
    }
    40% {
      top: 30%;
      right: 35%;
      transform: rotate(0);
      border: solid 1px #9EB394;
      z-index: 10;
    }
    50% {
      top: 5%;
      right: 5%;
      transform: rotate(-1deg);
      border: solid 1px #ECC2AA;
      z-index: 1;
    }
    60% {
      top: 5%;
      right: 10%;
      transform: rotate(1deg);
      border: solid 1px #35447B;
      z-index: 2;
    }
    70% {
      top: 30%;
      right: 2%;
      transform: rotate(0);
      border: solid 1px #BA2A2A;
      z-index: 3;
    }
    80% {
      top: 10%;
      right: 15%;
      transform: rotate(1deg);
      border: solid 1px #350023;
      z-index: 4;
    }
    90% {
      top: 3%;
      right: 20%;
      transform: rotate(-5deg);
      border: solid 1px #A7B4AA;
      z-index: 5;
    }
    100% {
      top: 2%;
      right: 25%;
      transform: rotate(6deg);
      border: solid 1px #435847;
      z-index: 6;
    }
  }
`

const _oneImg07 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 45%;
  bottom: 5%;
  right: 35%;
  transform: rotate(-6deg);
  z-index: 7;
  animation: anime-_oneImg07 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg07 {
    0% {
      bottom: 5%;
      right: 35%;
      transform: rotate(-6deg);
      border: solid 1px #435847;
      z-index: 7;
      filter: invert(0);
    }
    10% {
      bottom: 5%;
      right: 15%;
      transform: rotate(4deg);
      border: solid 1px #75394D;
      z-index: 8;
      filter: invert(100%);
    }
    20% {
      bottom: 30%;
      right: 7%;
      transform: rotate(-2deg);
      border: solid 1px #398793;
      z-index: 9;
      filter: invert(0);
    }
    30% {
      bottom: 30%;
      right: 35%;
      transform: rotate(0);
      border: solid 1px #F4CA66;
      z-index: 10;
    }
    40% {
      bottom: 10%;
      right: 25%;
      transform: rotate(6deg);
      border: solid 1px #9EB394;
      z-index: 1;
    }
    50% {
      bottom: 15%;
      right: 5%;
      transform: rotate(-2deg);
      border: solid 1px #ECC2AA;
      z-index: 2;
    }
    60% {
      bottom: 5%;
      right: 10%;
      transform: rotate(0);
      border: solid 1px #35447B;
      z-index: 3;
    }
    70% {
      bottom: 30%;
      right: 2%;
      transform: rotate(4deg);
      border: solid 1px #BA2A2A;
      z-index: 4;
    }
    80% {
      bottom: 10%;
      right: 15%;
      transform: rotate(-3deg);
      border: solid 1px #350023;
      z-index: 5;
    }
    90% {
      bottom: 20%;
      right: 20%;
      transform: rotate(5deg);
      border: solid 1px #A7B4AA;
      z-index: 6;
    }
    100% {
      bottom: 5%;
      right: 35%;
      transform: rotate(-6deg);
      border: solid 1px #435847;
      z-index: 7;
      filter: invert(0);
    }
  }
`

const _oneImg08 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 35%;
  bottom: 20%;
  right: 5%;
  transform: rotate(3deg);
  z-index: 8;
  animation: anime-_oneImg08 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg08 {
    0% {
      bottom: 20%;
      right: 5%;
      transform: rotate(3deg);
      border: solid 1px #435847;
      z-index: 8;
      filter: invert(100%);
    }
    10% {
      bottom: 15%;
      right: 25%;
      transform: rotate(-3deg);
      border: solid 1px #75394D;
      z-index: 9;
      filter: invert(0);
    }
    20% {
      bottom: 30%;
      right: 35%;
      transform: rotate(0);
      border: solid 1px #398793;
      z-index: 10;
    }
    30% {
      bottom: 5%;
      right: 5%;
      transform: rotate(-2deg);
      border: solid 1px #F4CA66;
      z-index: 1;
    }
    40% {
      bottom: 10%;
      right: 5%;
      transform: rotate(2deg);
      border: solid 1px #9EB394;
      z-index: 2;
    }
    50% {
      bottom: 25%;
      right: 15%;
      transform: rotate(-4deg);
      border: solid 1px #ECC2AA;
      z-index: 3;
    }
    60% {
      bottom: 35%;
      right: 20%;
      transform: rotate(-1deg);
      border: solid 1px #35447B;
      z-index: 4;
    }
    70% {
      bottom: 20%;
      right: 10%;
      transform: rotate(2deg);
      border: solid 1px #BA2A2A;
      z-index: 5;
    }
    80% {
      bottom: 15%;
      right: 25%;
      transform: rotate(-1deg);
      border: solid 1px #350023;
      z-index: 6;
    }
    90% {
      bottom: 10%;
      right: 25%;
      transform: rotate(-5deg);
      border: solid 1px #A7B4AA;
      z-index: 7;
      filter: invert(0);
    }
    100% {
      bottom: 20%;
      right: 5%;
      transform: rotate(3deg);
      border: solid 1px #435847;
      z-index: 8;
      filter: invert(100%);
    }
  }
`

const _oneImg09 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 45%;
  top: 30%;
  left: 5%;
  transform: rotate(-3deg);
  z-index: 9;
  animation: anime-_oneImg09 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg09 {
    0% {
      top: 30%;
      left: 5%;
      transform: rotate(-3deg);
      border: solid 1px #435847;
      z-index: 9;
      filter: invert(0);
    }
    10% {
      top: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #75394D;
      z-index: 10;
    }
    20% {
      top: 10%;
      left: 20%;
      transform: rotate(-2deg);
      border: solid 1px #398793;
      z-index: 1;
    }
    30% {
      top: 5%;
      left: 5%;
      transform: rotate(4deg);
      border: solid 1px #F4CA66;
      z-index: 2;
    }
    40% {
      top: 15%;
      left: 3%;
      transform: rotate(2deg);
      border: solid 1px #9EB394;
      z-index: 3;
    }
    50% {
      top: 20%;
      left: 10%;
      transform: rotate(-2deg);
      border: solid 1px #ECC2AA;
      z-index: 4;
    }
    60% {
      top: 5%;
      left: 5%;
      transform: rotate(5deg);
      border: solid 1px #35447B;
      z-index: 5;
    }
    70% {
      top: 10%;
      left: 3%;
      transform: rotate(-3deg);
      border: solid 1px #BA2A2A;
      z-index: 6;
    }
    80% {
      top: 15%;
      left: 5%;
      transform: rotate(3deg);
      border: solid 1px #350023;
      z-index: 7;
      filter: invert(0);
    }
    90% {
      top: 10%;
      left: 20%;
      transform: rotate(-1deg);
      border: solid 1px #A7B4AA;
      z-index: 8;
      filter: invert(100%);
    }
    100% {
      top: 30%;
      left: 5%;
      transform: rotate(-3deg);
      border: solid 1px #435847;
      z-index: 9;
      filter: invert(0);
    }
  }
`

const _oneImg10 = css`
  position: absolute;
  border: solid 1px #211E1E;
  font-size: 0;
  background-color: #fff;
  width: 35%;
  bottom: 30%;
  left: 35%;
  transform: rotate(0);
  z-index: 10;
  animation: anime-_oneImg10 14s ease .5s infinite normal forwards running;
  
  @keyframes anime-_oneImg10 {
    0% {
      bottom: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #435847;
      z-index: 10;
    }
    10% {
      bottom: 5%;
      left: 20%;
      transform: rotate(5deg);
      border: solid 1px #75394D;
      z-index: 1;
    }
    20% {
      bottom: 7%;
      left: 5%;
      transform: rotate(-2deg);
      border: solid 1px #398793;
      z-index: 2;
    }
    30% {
      bottom: 15%;
      left: 5%;
      transform: rotate(7deg);
      border: solid 1px #F4CA66;
      z-index: 3;
    }
    40% {
      bottom: 5%;
      left: 20%;
      transform: rotate(2deg);
      border: solid 1px #9EB394;
      z-index: 4;
    }
    50% {
      bottom: 10%;
      left: 10%;
      transform: rotate(-4deg);
      border: solid 1px #ECC2AA;
      z-index: 5;
    }
    60% {
      bottom: 15%;
      left: 5%;
      transform: rotate(-2deg);
      border: solid 1px #35447B;
      z-index: 6;
    }
    70% {
      bottom: 20%;
      left: 0%;
      transform: rotate(0);
      border: solid 1px #BA2A2A;
      z-index: 7;
      filter: invert(0);
    }
    80% {
      bottom: 5%;
      left: 5%;
      transform: rotate(-3deg);
      border: solid 1px #350023;
      z-index: 8;
      filter: invert(100%);
    }
    90% {
      bottom: 15%;
      left: 10%;
      transform: rotate(2deg);
      border: solid 1px #A7B4AA;
      z-index: 9;
      filter: invert(0);
    }
    100% {
      bottom: 30%;
      left: 35%;
      transform: rotate(0);
      border: solid 1px #435847;
      z-index: 10;
    }
  }
`

const _concept = css`
  width: clamp(50%, (1000px - 100vw)*1000, 100%);
`

const Partner = () => {
  return (
    <section className={picArea}>
      <div className={flexImgs}>
        <div className={_img10}>
          
          <div className={_oneImg01}>
            <StaticImage
              src="../../images/top/partner/009.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg02}>
            <StaticImage
              src="../../images/top/partner/002.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg03}>
            <StaticImage
              src="../../images/top/partner/003.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg04}>
            <StaticImage
              src="../../images/top/partner/004.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg05}>
            <StaticImage
              src="../../images/top/partner/005.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg06}>
            <StaticImage
              src="../../images/top/partner/006.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg07}>
            <StaticImage
              src="../../images/top/partner/007.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg08}>
            <StaticImage
              src="../../images/top/partner/008.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg09}>
            <StaticImage
              src="../../images/top/partner/001.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
          
          <div className={_oneImg10}>
            <StaticImage
              src="../../images/top/partner/010.png"
              alt=""
              placeholder="blurred"
              quality="40"
            />
          </div>
        
        </div>
        <div className={_concept} id="_concept">
          <h2>多くのクリエイター、エンジニアと協業しています。<br />フリーランスの方、大歓迎です！</h2>
          <p>「新しい」を常に模索したく、たくさんの外部パートナーさんとコラボをしております。<br />多くの人と出会い、お互いに成長できることも魅力です。</p>
          <p>リモート作業を取り入れ、機敏な対応を心がけております。<br />チーム全体で最高の成果物を目指します。</p>
          <p>※ パートナーさん、募集中です！</p>
        </div>
      </div>
    </section>
  )
}

export default Partner