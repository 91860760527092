import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { css } from 'linaria'
import Layout from '../layouts'
import SvgCat from '../components/top/SvgCat'
import MtFuji from '../components/top/MtFuji'
import Partner from '../components/top/Partner'
import Bonsai from '../components/top/Bonsai'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import "../scss/swiper.scss"
const moment = require('moment');

const onePost = css`
  display: block;
  text-decoration: none;
`

const postTime = css`
  color: #402E2B;
`

const postTags = css`
  color: #BA2636;
`

const oneTag = css`
  &:before {
    content: '#';
  }
  
  &:after {
    content: ' ';
  }
`

const blogIndex = css`
  font-size: clamp(22px, 2.4vw, 36px);
  padding: 30px clamp(20px, 5%, 80px) 30px calc(max(5%, 20px) + 250px);
  
  @media (max-width:1000px) {
    padding: 30px clamp(20px, 5%, 80px);
  }
`

const slideWrapper = css`
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left:0;
    top: -60px;
    
    transform: skewY(2deg);
    z-index: -1;
    width:100%;
    height:100%;
    background:#eee;
    border-top-right-radius: 1000px 100px;
    border-bottom-left-radius: 1800px 100px;
  }
`

const Home = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      
      <SvgCat />
      <MtFuji />
      <Partner />
      <Bonsai />
      <h2 className={blogIndex}>新着ブログ</h2>
      <Swiper
        modules={[ Autoplay ]}
        spaceBetween={16}
        slidesPerView={3}
        speed={7000}
        loop={true}
        className={slideWrapper}
        allowTouchMove={false}
        autoplay={{delay: 0, disableOnInteraction: false }}
        freeMode={{enabled: true, momentum: false }}
      >
        {edges.map(({ node }, index) => (
          <SwiperSlide key={index}>
            <Link to={node.frontmatter.path} className={onePost}>
              <div>
                <div>
                  <GatsbyImage
                    image={node.frontmatter.cover.childImageSharp.gatsbyImageData}
                    alt={node.frontmatter.title} />
                </div>
                <div className={postTime}><time dateTime={node.frontmatter.date}>{moment(node.frontmatter.date).format("YYYY年MM月DD日")}</time></div>
                <div className={postTags}>
                  {node.frontmatter.tags.map(tag =>
                    <span className={oneTag} key={tag}>
                      {tag}
                    </span>
                  )}
                </div>
                <h3>{node.frontmatter.title}</h3>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Layout>
  );
}

export const query = graphql`{
  allMarkdownRemark(filter: {frontmatter: {status: {eq: "published"}}}) {
    edges {
      node {
        id
        excerpt(pruneLength: 100)
        frontmatter {
          title
          tags
          date
          path
          cover {
            childImageSharp {
              gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
export default Home
