import React from 'react'
import { css, cx } from 'linaria'

const facePre = css`
  stroke: #435847;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(3.75811, 0, 0, 3.75964, -6.4, -11.79);
  animation: svg-facePre 2s ease .5s 1 normal forwards running, svg-faceMove .5s ease 4s 1 normal forwards running;
  
  @keyframes svg-facePre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-faceMove {
    0% {
      transform: matrix(3.75811, 0, 0, 3.75964, -6.4, -11.79);
    }
    6.25% {
      transform: matrix(3.75811, 0, 0, 3.75964, -16.4, -11.79);
    }
    18.75% {
      transform: matrix(3.75811, 0, 0, 3.75964, 6.4, -11.79);
    }
    25% {
      transform: matrix(3.75811, 0, 0, 3.75964, -6.4, -11.79);
    }
    31.25% {
      transform: matrix(3.75811, 0, 0, 3.75964, -16.4, -11.79);
    }
    43.75% {
      transform: matrix(3.75811, 0, 0, 3.75964, 6.4, -11.79);
    }
    50% {
      transform: matrix(3.75811, 0, 0, 3.75964, -6.4, -11.79);
    }
    56.25% {
      transform: matrix(3.75811, 0, 0, 3.75964, -16.4, -11.79);
    }
    68.75% {
      transform: matrix(3, 0, 0, 3, -30, 60);
    }
    75% {
      transform: matrix(2.5, 0, 0, 2.5, 0, 80);
    }
    81.25% {
      transform: matrix(2, 0, 0, 2, 80, 130);
    }
    93.75% {
      transform: matrix(1, 0, 0, 1, 150, 170);
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 200, 200);
    }
  }
`

const bodyPre = css`
  stroke: #9B1F1F;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(4.06484, 0, 0, 4.06158, -72.347, -47);
  animation: svg-bodyPre 2s 1s both, svg-bodyMove 0.5s 4s both;
  
  @keyframes svg-bodyPre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-bodyMove {
    0% {
      transform: matrix(4.06484, 0, 0, 4.06158, -72.347, -47);
    }
    6.25% {
      transform: matrix(4.06484, 0, 0, 4.06158, -70, -47);
    }
    18.75% {
      transform: matrix(4.06484, 0, 0, 4.06158, -74, -47);
    }
    25% {
      transform: matrix(4.06484, 0, 0, 4.06158, -72.347, -47);
    }
    31.25% {
      transform: matrix(4.06484, 0, 0, 4.06158, -70, -47);
    }
    43.75% {
      transform: matrix(4.06484, 0, 0, 4.06158, -74, -47);
    }
    50% {
      transform: matrix(4.06484, 0, 0, 4.06158, -72.347, -47);
    }
    56.25% {
      transform: matrix(4.06484, 0, 0, 4.06158, -70, -47);
    }
    68.75% {
      transform: matrix(3, 0, 0, 3, 220, 20);
    }
    75% {
      transform: matrix(2, 0, 0, 2, 450, 100);
    }
    81.25% {
      transform: matrix(1, 0, 0, 1, 650, 160);
    }
    93.75% {
      transform: matrix(0.7, 0, 0, 0.7, 750, 180);
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 850, 200);
    }
  }
`
const perfect = css`
  opacity: 0;
  animation: svg-perfect 1s ease 4s 1 normal forwards running;
  
  //@-moz-document url-prefix() {
  //  animation: svg-perfect 1s ease-out 3.3s 1 normal forwards running;
  //}
  
  @keyframes svg-perfect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const tailRoop = css`
  opacity: 0;
  transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046);
  animation: svg-perfect 1s ease 4s 1 normal forwards running, svg-tailRoop 4s ease 5s infinite alternate forwards running;
  
  //@-moz-document url-prefix() {
  //  animation: svg-perfect 1s ease-out 3.3s 1 normal forwards running, svg-tailRoop 4s ease 5s infinite alternate forwards running;
  //}
  
  @keyframes svg-perfect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes svg-tailRoop {
    0% {
      transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046);
    }
    10% {
      transform: matrix(4.78, 0, 0, 4.78449, -310, -110);
    }
    20% {
      transform: matrix(4.78, 0, 0, 4.78449, -309, -110);
    }
    30% {
      transform: matrix(4.78, 0, 0, 4.78449, -310, -109);
    }
    40% {
      transform: matrix(4.78, 0, 0, 4.78449, -311, -111);
    }
    50% {
      transform: matrix(4.78, 0, 0, 4.78449, -312, -112);
    }
    60% {
      transform: matrix(4.78, 0, 0, 4.78449, -311.47, -113);
    }
    70% {
      //transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046)
      transform: matrix(4.73, 0.3, -0.7, 4.3, -300, -100)
    }
    100% {
      transform: matrix(4.78, 0, 0, 4.78449, -312, -109);
    }
  }
`

const tailPre = css`
  stroke: #7f7460;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046);
  animation: svg-tailPre 2s ease 2s 1 normal forwards running, svg-tailMove .5s ease 4s 1 normal forwards running;
  opacity: 1;
  
  @keyframes svg-tailPre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-tailMove {
    0% {
      transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046);
    }
    6.25% {
      transform: matrix(4.78, 0, 0, 4.78449, -300, -111.046);
    }
    18.75% {
      transform: matrix(4.78, 0, 0, 4.78449, -320, -111.046);
    }
    25% {
      transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046);
    }
    31.25% {
      transform: matrix(4.78, 0, 0, 4.78449, -300, -111.046);
    }
    43.75% {
      transform: matrix(4.78, 0, 0, 4.78449, -320, -111.046);
    }
    50% {
      transform: matrix(4.78, 0, 0, 4.78449, -311.47, -111.046);
    }
    56.25% {
      transform: matrix(4.78, 0, 0, 4.78449, -300, -111.046);
    }
    68.75% {
      transform: matrix(4, 0, 0, 4, 0, -92);
    }
    75% {
      transform: matrix(3, 0, 0, 3, 450, 0);
    }
    81.25% {
      transform: matrix(2, 0, 0, 2, 850, 50);
    }
    93.75% {
      transform: matrix(1, 0, 0, 1, 1250, 50);
      opacity: 1;
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 1650, 200);
      opacity: 0;
    }
  }
`

const legsPreFL = css`
  stroke: #398793;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(4.33263, 0, 0, 4.33534, -126.634, -83.235);
  animation: svg-legsPre 2s ease 1.5s 1 normal forwards running, svg-legsFLMove .5s ease 4s 1 normal forwards running;
  
  @keyframes svg-legsPre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-legsFLMove {
    0% {
      transform: matrix(4.33263, 0, 0, 4.33534, -126.634, -83.235);
    }
    6.25% {
      transform: matrix(4.33263, 0, 0, 4.33534, -137, -83.235);
    }
    18.75% {
      transform: matrix(4.33263, 0, 0, 4.33534, -125, -83.235);
    }
    25% {
      transform: matrix(4.33263, 0, 0, 4.33534, -126.634, -83.235);
    }
    31.25% {
      transform: matrix(4.33263, 0, 0, 4.33534, -137, -83.235);
    }
    43.75% {
      transform: matrix(4.33263, 0, 0, 4.33534, -125, -83.235);
    }
    50% {
      transform: matrix(4.33263, 0, 0, 4.33534, -126.634, -83.235);
    }
    56.25% {
      transform: matrix(4.33263, 0, 0, 4.33534, -137, -83.235);
    }
    68.75% {
      transform: matrix(4, 0, 0, 4, -100, -50);
    }
    75% {
      transform: matrix(3, 0, 0, 3, 80, 70);
    }
    81.25% {
      transform: matrix(2, 0, 0, 2, 230, 170);
    }
    93.75% {
      transform: matrix(1, 0, 0, 1, 410, 280);
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 550, 350);
    }
  }
`

const legsPreFR = css`
  stroke: #398793;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(3.54477, 0, 0, 3.55883, 24.484, 12.095);
  animation: svg-legsPre 2s ease 1.5s 1 normal forwards running, svg-legsFRMove .5s ease 4s 1 normal forwards running;
  
  @keyframes svg-legsPre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-legsFRMove {
    0% {
      transform: matrix(3.54477, 0, 0, 3.55883, 24.484, 12.095);
    }
    6.25% {
      transform: matrix(3.54477, 0, 0, 3.55883, 14, 12.095);
    }
    18.75% {
      transform: matrix(3.54477, 0, 0, 3.55883, 35, 12.095);
    }
    25% {
      transform: matrix(3.54477, 0, 0, 3.55883, 24.484, 12.095);
    }
    31.25% {
      transform: matrix(3.54477, 0, 0, 3.55883, 14, 12.095);
    }
    43.75% {
      transform: matrix(3.54477, 0, 0, 3.55883, 35, 12.095);
    }
    50% {
      transform: matrix(3.54477, 0, 0, 3.55883, 24.484, 12.095);
    }
    56.25% {
      transform: matrix(3.54477, 0, 0, 3.55883, 14, 12.095);
    }
    68.75% {
      transform: matrix(3, 0, 0, 3, 140, 60);
    }
    75% {
      transform: matrix(2, 0, 0, 2, 330, 180);
    }
    81.25% {
      transform: matrix(1.3, 0, 0, 1.3, 480, 260);
    }
    93.75% {
      transform: matrix(1, 0, 0, 1, 545, 290);
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 680, 350);
    }
  }
`

const legsPreRL = css`
  stroke: #398793;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(3.96454, 0, 0, 3.96199, -20.7, -29.076);
  animation: svg-legsPre 2s ease 1.5s 1 normal forwards running, svg-legsRLMove .5s ease 4s 1 normal forwards running;
  
  @keyframes svg-legsPre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-legsRLMove {
    0% {
      transform: matrix(3.96454, 0, 0, 3.96199, -20.7, -29.076);
    }
    6.25% {
      transform: matrix(3.96454, 0, 0, 3.96199, -31, -29.076);
    }
    18.75% {
      transform: matrix(3.96454, 0, 0, 3.96199, -10, -29.076);
    }
    25% {
      transform: matrix(3.96454, 0, 0, 3.96199, -20.7, -29.076);
    }
    31.25% {
      transform: matrix(3.96454, 0, 0, 3.96199, -31, -29.076);
    }
    43.75% {
      transform: matrix(3.96454, 0, 0, 3.96199, -10, -29.076);
    }
    50% {
      transform: matrix(3.96454, 0, 0, 3.96199, -20.7, -29.076);
    }
    56.25% {
      transform: matrix(3.96454, 0, 0, 3.96199, -31, -29.076);
    }
    68.75% {
      transform: matrix(3, 0, 0, 3, 320, 80);
    }
    75% {
      transform: matrix(2, 0, 0, 2, 670, 200);
    }
    81.25% {
      transform: matrix(1.5, 0, 0, 1.5, 830, 245);
    }
    93.75% {
      transform: matrix(1, 0, 0, 1, 1020, 300);
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 1270, 400);
    }
  }
`

const legsPreRR = css`
  stroke: #398793;
  stroke-width: 0;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  fill-opacity: 0;
  transform: matrix(3.585, 0, 0, 3.57523, 57.015, 21.73);
  animation: svg-legsPre 2s ease 1.5s 1 normal forwards running, svg-legsRRMove .5s ease 4s 1 normal forwards running;
  
  @keyframes svg-legsPre {
    0% {
      fill-opacity: 0;
      stroke-dashoffset: 2000px;
      stroke-width: 3px;
    }
    50% {
      fill-opacity: 0;
      stroke-dashoffset: 0;
      stroke-width: 3px;
    }
    100% {
      fill-opacity: 1;
      stroke-dashoffset: 0;
      stroke-width: 0;
    }
  }
  
  @keyframes svg-legsRRMove {
    0% {
      transform: matrix(3.585, 0, 0, 3.57523, 57.015, 21.73);
    }
    6.25% {
      transform: matrix(3.585, 0, 0, 3.57523, 46, 21.73);
    }
    18.75% {
      transform: matrix(3.585, 0, 0, 3.57523, 68, 21.73);
    }
    25% {
      transform: matrix(3.585, 0, 0, 3.57523, 57.015, 21.73);
    }
    31.25% {
      transform: matrix(3.585, 0, 0, 3.57523, 46, 21.73);
    }
    43.75% {
      transform: matrix(3.585, 0, 0, 3.57523, 68, 21.73);
    }
    50% {
      transform: matrix(3.585, 0, 0, 3.57523, 57.015, 21.73);
    }
    56.25% {
      transform: matrix(3.585, 0, 0, 3.57523, 46, 21.73);
    }
    68.75% {
      transform: matrix(3, 0, 0, 3, 220, 80);
    }
    75% {
      transform: matrix(2, 0, 0, 2, 510, 200);
    }
    81.25% {
      transform: matrix(1.5, 0, 0, 1.5, 660, 270);
    }
    93.75% {
      transform: matrix(1, 0, 0, 1, 800, 330);
    }
    100% {
      transform: matrix(0.3, 0, 0, 0.3, 1000, 390);
    }
  }
`

const wrapper = css`
  padding: 30px clamp(20px, 5%, 80px) 30px calc(max(5%, 20px) + 250px);
  
  @media (max-width:1000px) {
    padding: 30px clamp(20px, 5%, 80px);
  }
`

export const svgAnimation = cx(css`
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 5%;
  transform: rotate(5deg);
  
  @media (max-width:1000px) {
    transform: rotate(0);
  }
  
  &:after {
    content: '';
    position: absolute;
    left:0;
    top: 0;
    
    //transform: skewY(2deg);
    transform: rotate(-5deg);
    z-index: -1;
    width:100%;
    height:100%;
    background:#eee;
    border-top-right-radius: 2000px 300px;
    border-bottom-left-radius: 2000px 100px;
    
    @media (max-width:1000px) {
      transform: rotate(0);
    }
  }
`, wrapper)

const SvgCat = () => {
  return (
    <div className={svgAnimation}>
      <svg viewBox="0 0 1920 500" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern href="#a" id="j" patternTransform="matrix(.2308 0 0 .23066 345.83 196.65)"/>
          <pattern patternUnits="userSpaceOnUse" width="30" height="60" id="a">
          <path style={{fill:"#f4ca66", fillOpacity:1, strokeWidth:.450099}} d="M0 0h7.938v15.875H0z" transform="scale(3.77953)"/>
          <g style={{display:"inline"}}>
          <path style={{fill:"#398793", fillOpacity:1, strokeWidth:.450099}} d="m3.969 0 3.969 7.938-3.97 7.937L0 7.937Z" transform="scale(3.77953)"/>
          </g>
          <path style={{display:"inline", fill:"#e59a57", fillOpacity:1, strokeWidth:.450099}} d="m.265 0 7.673 15.61v.265h-.265L0 .265V0Z" transform="scale(3.77953)"/>
          <path style={{display:"inline", fill:"#e59a57", fillOpacity:1, strokeWidth:.450099}} d="M7.938.265.264 15.875H0v-.265L7.673 0h.264Z" transform="scale(3.77953)"/>
          </pattern>
          
          <pattern patternUnits="userSpaceOnUse" width="25" height="25" id="n">
          <path style={{fill:"#ebe9da"}} d="M0 0h13.229v13.229H0z" transform="scale(1.89)"/>
          <path style={{fill:"#435847"}} d="M5.544 5.112c-.149.482.069 1.094-.238 1.495-.275.359-.786.525-1.236.556a2.322 2.322 0 0 1-1.475-.434c-.265-.192-.382-.534-.559-.81-.256-.4-.742-.753-.728-1.228.018-.606.596-1.07 1.017-1.506.391-.404.805-.984 1.368-.99.986-.009 2.176.736 2.43 1.69.118.436-.445.794-.579 1.227ZM4.814 10.587c-.115.485.249 1.088-.038 1.495-.296.42-.927.494-1.436.557-.566.07-1.336.229-1.696-.213-.289-.353.292-.948.106-1.364-.2-.449-1.092-.41-1.172-.896-.097-.597.517-1.164 1.017-1.505.6-.41 1.416-.622 2.129-.48.668.133 1.496.52 1.67 1.179.115.437-.475.787-.58 1.227ZM11.136 10.008c-.046.476-.351.928-.704 1.25-.334.306-.785.558-1.237.557-.491 0-.892-.412-1.341-.611-.333-.148-.737-.184-1.002-.434-.378-.354-.765-.869-.706-1.383.077-.671.756-1.156 1.305-1.55.488-.35 1.083-.744 1.678-.657.605.09 1.092.634 1.444 1.134.342.487.62 1.102.563 1.694zM12.222 3.513c-.142.475-.149 1.04-.483 1.406-.304.334-.785.526-1.236.557-.511.035-.991-.265-1.474-.435-.507-.177-1.212-.151-1.49-.61-.353-.58.036-1.364.18-2.027.104-.474.13-1.042.485-1.372C8.69.58 9.471.395 10.126.508c.972.167 2.086.764 2.431 1.688.159.425-.206.883-.335 1.317z" transform="scale(1.88762)"/>
          </pattern>
          
          <pattern patternUnits="userSpaceOnUse" width="100" height="2" id="l">
          <path style={{fill:"#5d3c7f"}} d="M0 0h.529v.529H0z" transform="scale(3.77953)"/>
          <path style={{fill:"#5d3c7f"}} d="M1.058 0h1.587v.529H1.058z" transform="scale(3.77953)"/>
          <path style={{fill:"#2d2430"}} d="M.529 0h.529v.529H.529zM2.646 0h.529v.529h-.529z" transform="scale(3.77953)"/>
          <path style={{fill:"#2d2430"}} d="M6.879 0h.265v.529h-.265zM15.081 0h.265v.529h-.265z" transform="scale(3.77953)"/>
          <path style={{fill:"#934889"}} d="M15.346 0h1.852v.529h-1.852zM17.727 0h1.852v.529h-1.852z" transform="scale(3.77953)"/>
          <path style={{fill:"#934889"}} d="M25.135 0h1.323v.529h-1.323z" transform="scale(3.77953)"/>
          <path style={{fill:"#2d2430"}} d="M13.758 0h.529v.529h-.529zM17.198 0h.529v.529h-.529zM21.167 0h.529v.529h-.529zM22.225 0h.529v.529h-.529zM24.606 0h.529v.529h-.529z" transform="scale(3.77953)"/>
          <path style={{fill:"#2d2430"}} d="M23.548 0h.265v.529h-.265z" transform="scale(3.77953)"/>
          <path style={{fill:"#c9b087"}} d="M14.287 0h.794v.529h-.794z" transform="scale(3.77953)"/>
          <path style={{fill:"#2d2430"}} d="M7.938 0h.794v.529h-.794zM19.579 0h.794v.529h-.794z" transform="scale(3.77953)"/>
          <path style={{fill:"#c14333"}} d="M20.373 0h.794v.529h-.794z" transform="scale(3.77953)"/>
          <path style={{fill:"#9b1f1f"}} d="M8.731 0h5.027v.529H8.731z" transform="scale(3.77953)"/>
          <path style={{fill:"#9b1f1f"}} d="M21.696 0h.529v.529h-.529z" transform="scale(3.77953)"/>
          <path style={{fill:"#9b1f1f"}} d="M22.754 0h.794v.529h-.794zM23.813 0h.794v.529h-.794z" transform="scale(3.77953)"/>
          <path style={{fill:"#c14333"}} d="M7.144 0h.794v.529h-.794z" transform="scale(3.77953)"/>
          <path style={{fill:"#c9b087"}} d="M3.175 0h3.704v.529H3.175z" transform="scale(3.77953)"/>
          </pattern>
          
          <pattern patternUnits="userSpaceOnUse" width="16" height="16" id="b">
          <path style={{fill:"#7f7460"}} d="M0 0h4.233v4.233H0z" transform="scale(3.77953)"/>
          <path style={{fill:"#ecc2aa"}} d="M2.117 3.913a.511.511 0 0 0-.118-.361c-.216-.248-.526-.398-.8-.572-.361-.232-.533-.703-.642-1.09-.07-.247-.138-.546 0-.784A.629.629 0 0 1 .91.862c.556-.079.67.42.972.761.118.134.259.251.368.392.009.011.218.356.228.353.024-.006.148-.609.172-.674.126-.344.435-.689.69-.933.015-.014.241-.182.314-.134.097.065.111.194.133.298.18.847-.25 1.512-.839 2.07-.245.233-.443.515-.635.792-.05.073-.196.193-.196.126" transform="scale(3.77953)"/>
          </pattern>
          
          <clipPath id="o">
          <path d="M89.06 110.892c-36.137.686-47.234 2.233-57.926-35.081-1.778-6.203-4.572-17.255-2.133-23.742 3.579-9.515 11.435-4.377 12.438-6.378.487-.972-11.372-17.858-11.372-25.868 0-1.181 1.198 2.11 2.133 2.835 3.874 3.004 11.944 7.303 15.28 10.63 13.395 13.357 19.768 7.796 37.315 7.796.764 0 3.603 1.014 3.909.709 2.107-2.1 3.421-8.55 4.975-11.34 1.19-2.135 19.793-20.66 20.612-19.843 2.54 2.532-10.306 29.481-10.306 35.435 0 1.402 5.737 1.038 6.752 1.417 5.012 1.874 8.197 6.177 9.95 10.985 5.345 14.655-18.955 47.39-31.628 52.445z"/>
          </clipPath>
          
          <clipPath id="m">
          <path d="M115.474 66.163c59.627-10.413 117.594-13.475 174.51-23.894 10.595-1.985 49.064-9.181 59.589-6.026 22.42 6.721 46.645 17.004 39.371 46.08-3.81 15.23-20.953 11.941-32.987 15.95-43.45 14.473-93.05 6.443-137.267-.355-9.7-1.491-20.004.796-29.794-.709-17.261-2.654-34.675-7.068-51.785-11.342-9.493-2.372-21.995-.812-30.504-5.672-1.784-1.018 6.709-12.636 8.867-14.032z"/>
          </clipPath>
          
          <clipPath id="k">
          <path d="M163.77 92.363c3.537-13.21.687.719-1.795 4.246-4.51 6.409-26.952 21.535-27.017 21.92-.883 5.272 26.895 6.016 30.927 0 1.293-1.928 12.607-28.828 12.442-28.991-.642-.639-3.86-.354-4.62-.354-3.73 0-6.908 1.016-9.937 3.179z"/>
          </clipPath>
          
          <clipPath id="i">
          <path d="M187.079 86.86c7.606-1.783 37.982 31.704 36.437 33.248-4.867 4.867-19.535 2.24-24.41-1.415-2.21-1.657-1.244-7.008-3.537-8.842-4.287-3.43-13.588-10.776-14.504-16.271-.884-5.304 5-8.074 6.014-6.72z"/>
          </clipPath>
          
          <clipPath id="e">
          <path d="M302.14 106.497c-1.109-8.21-4.556-4.975-8.196-1.347-5.767 5.748-16.362 8.303-21.292 14.855-1.389 1.846 8.669 3.5 9.581 3.89 1.314.562 8.166 2.827 9.582 1.769 1.47-1.1 17.171-24.59 16.678-25.82-3.53-8.793-11.346 2.466-11.402 6.635"/>
          </clipPath>
          
          <clipPath id="g">
          <path d="M330.566 104.398c-1.069-11.71-.055-.055 0 0 2.064 2.057 5.026 1.595 7.228 5.672 4.839 8.96 8.894 21.105 19.896 14.524 2.527-1.512 6.066-1.57 7.816-3.897 2.353-3.128-6.076-8.07-7.106-9.918-1.06-1.903-15.901-22.451-16.343-22.671-.19-.095-5.122 3.93-5.684 4.25-1.618.923-7.63 1.841-8.171 3.189-.697 1.737 1.895 7.16 2.364 8.851z"/>
          </clipPath>
          
          <clipPath id="c">
          <path d="M370.79 69.046c6.504-12.19 14.707-27.208 28.46-32.365 26.317-9.865 23.716 29.686 37 27.03 8.06-1.612 10.976-25.53 15.297-32.009 3.2-4.799 7.972 1.06 6.403 4.98-3.529 8.82-5.926 17.895-10.317 26.673-3.24 6.479-4.861 7.765-11.384 11.026-20.15 10.072-22.914-42.852-41.267-19.917-4.667 5.831-11.186 12.013-18.5 14.938-4.723 1.888-8.182-2.446-8.182 1.706"/>
          </clipPath>
        
        </defs>
        
        <path className={tailPre} style={{fill:"url(#b)"}} d="M368.3 29.898h89.958v45.773H368.3z"/>
        
        <path className={legsPreRR} style={{fill:"url(#j)"}} d="M272.521 96.573h35.983v29.369h-35.983z"/>
        <path className={legsPreRL} style={{fill:"url(#j)"}} d="M328.083 88.106h37.835v38.365h-37.835z"/>
        <path className={legsPreFR} style={{fill:"url(#j)"}} d="M180.975 86.519h42.598v36.248h-42.598z"/>
        <path className={legsPreFL} style={{fill:"url(#j)"}} d="M134.938 86.783h43.392v35.983h-43.392z"/>
        
        <path className={bodyPre} style={{fill:"url(#l)"}} d="M106.362 35.454H390.26v70.908H106.362z"/>
        
        <path className={facePre} style={{fill:"url(#n)"}} d="M28.046 10.583h93.398v100.542H28.046z"/>
        
        <path className={tailRoop} style={{fill:"url(#b)"}} clipPath="url(#c)" d="M368.3 29.898h89.958v45.773H368.3z"/>
        
        <path className={perfect} style={{fill:"url(#j)"}} clipPath="url(#e)" transform="matrix(3.585 0 0 3.57523 57.015 21.73)" d="M272.521 96.573h35.983v29.369h-35.983z"/>
        
        <path className={perfect} style={{fill:"url(#j)"}} clipPath="url(#g)" transform="matrix(3.96454 0 0 3.96199 -20.7 -29.076)" d="M328.083 88.106h37.835v38.365h-37.835z"/>
        
        <path className={perfect} style={{fill:"url(#j)"}} clipPath="url(#i)" transform="matrix(3.54477 0 0 3.55883 24.484 12.095)" d="M180.975 86.519h42.598v36.248h-42.598z"/>
        
        <path className={perfect} style={{fill:"url(#j)"}} clipPath="url(#k)" transform="matrix(4.33263 0 0 4.33534 -126.634 -83.235)" d="M134.938 86.783h43.392v35.983h-43.392z"/>
        
        <path className={perfect} style={{fill:"url(#l)"}} clipPath="url(#m)" transform="matrix(4.06484 0 0 4.06158 -72.347 -47)" d="M106.362 35.454H390.26v70.908H106.362z"/>
        
        <path className={perfect} style={{fill:"url(#n)"}} clipPath="url(#o)" transform="matrix(3.75811 0 0 3.75964 -6.4 -11.79)" d="M28.046 10.583h93.398v100.542H28.046z"/>
      </svg>
    </div>
        
  )
}

export default SvgCat