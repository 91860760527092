import React from 'react'
import { css } from 'linaria'
import { StaticImage } from "gatsby-plugin-image";

const kvArea = css`
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left:0;
    top: 0;
    
    transform: skewY(-3deg);
    z-index: -1;
    width:100%;
    height:100%;
    background:#eee;
    border-top-left-radius: 2000px 400px;
    border-bottom-right-radius: 2000px 200px;
  }
`

const flexKV = css`
  display: flex;
  flex-wrap: wrap;
`

const _concept = css`
  width: clamp(50%, (1000px - 100vw)*1000, 100%);
`

const _imgKV = css`
  width: clamp(50%, (1000px - 100vw)*1000, 100%);
`

const MtFuji = () => {
  return (
    <section className={kvArea}>
      <div className={flexKV}>
        <div className={_concept} id="_concept">
          <h2>静岡から世界へ<br />WEBで「楽しい」を実現します。</h2>
          <p>富士山の見えるところにあるWEB制作会社です。<br />日本中に、世界中に、私たちのできることを届けたいです。</p>
          <p>既成概念にとらわれず、自由な発想を大切にしています。<br />「こんなWEBサイトが欲しかった」と言っていただけることが喜びです。</p>
          <p>WEBのことがまったくわからない方にもご安心いただけるよう、企画から運用まですべてを行っております。<br />もちろん、部分的なリニューアルも。お気軽にお声がけください。</p>
        </div>
        <div className={_imgKV}>
          <div id="_uImageFrame">
            <StaticImage
              src="../../images/top/KV-20220615.png" // 相対パス
              alt="富士山"
              placeholder="blurred" // ボヤッと読み込ませるオプション。他にもいくつかある
              quality="40" // 画質
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MtFuji